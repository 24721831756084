<template>
  <v-dialog v-model="dialog" persistent :max-width="dialogWidth" class="scroll">
    <v-card class="px-5 pb-8 scroll">
      <v-container fluid v-if="page === 1">
        <v-row class="d-flex align-center py-4">
          <div>
            <v-radio-group
              v-model="filter.type"
              row
              class="mt-0"
              @change="applyFilter()"
            >
              <v-radio :label="$t('technician')" value="technician"></v-radio>
              <v-radio
                :label="$t('psychological')"
                value="psychological"
              ></v-radio>
            </v-radio-group>
          </div>
          <div>
            <v-checkbox
              v-model="filter.user"
              :label="$t('myteste')"
              class="mt-0"
              :value="currentUser.id"
              @change="applyFilter()"
            ></v-checkbox>
          </div>
          <v-spacer></v-spacer>
          <div class="px-4">
            <test-filter @apply="applyTestFilter" @clean="cleanFilter()" />
          </div>
          <div>
            <v-text-field
              v-model="textSearch"
              class="search"
              append-icon="mdi-magnify"
              :label="$t('searchText')"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </div>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mx-4 mt-0 scroll-test">
          <v-col cols="12" class="">
            <p class="text-center">
              {{ $t("selectTestSend") }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="4"
            xl="3"
            v-for="test in filteredItems"
            :key="test.title"
          >
            <test-card
              :selectedTests="selectedTests"
              :select="true"
              :test="test"
              @preview="showPreviewDialog"
              @select="selectTest"
            />
          </v-col>
        </v-row>

        <!-- <v-row justify="center" class="mt-0">
          <v-col cols="8">
            <pagination :totalPages="tests.totalPages" @changePage="changePage" />
          </v-col>
        </v-row> -->
      </v-container>
      <v-row v-else justify="center" class="pt-8">
        <v-col cols="12" md="12">
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                :label="$t('date_limit_test')"
                prepend-inner-icon="event"
                readonly
                v-on="on"
                dense
                outlined
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              :min="dateFormat()"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuDate = false"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="$refs.menuDate.save(date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="time"
            :label="$t('time_limit_test')"
            v-mask="'##:##'"
            placeholder="00:00"
            prepend-inner-icon="access_time"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-end align-center py-4 px-2">
        <v-btn text @click="closeDialog()" color="primary">{{
          $t("cancel")
        }}</v-btn>
        <v-btn color="primary" @click="page === 1 ? nextStep() : sendTest()">{{
          buttonsTitle
        }}</v-btn>
      </v-row>
    </v-card>
    <preview-test-dialog
      :dialog="previewDialog"
      :test="test"
      @close="previewDialog = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import {
  SEND_TESTS_IMPORTED_MUTATION,
  SEND_TESTS_IMPORTED_WITH_VACANCY_MUTATION,
} from "./../graphql/Mutation.service";
// import { GET_TESTS_ENTITY_QUERY } from '../../test/graphql/Query.service'
import { mapActions, mapGetters } from "vuex";
// import { formatError } from '@/utils'
// import Pagination from './../../../components/Pagination'
import moment from "moment";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import PreviewTestDialog from "./../../test/components/PreviewTestDialog.vue";
import TestFilter from "../../../components/TestFilter.vue";
import TestCard from "../../test/components/TestCard.vue";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import Test from "@/models/Test";
export default {
  name: "SelectTest",
  components: {
    PreviewTestDialog,
    ErrorDialog,
    // Pagination,
    ProgressDialog,
    SuccessDialog,
    TestCard,
    TestFilter,
  },
  mixins: [ADD_DIALOG, replaceSpecialCharsMixins],
  props: {
    dialog: Boolean,
    candidates: Array,
    vacancyTitle: String,
    vacancyId: String,
  },
  data: () => ({
    appliedFilter: null,
    date: null,
    filter: {
      areas: undefined,
      category: "",
      entity: undefined,
      positions: undefined,
      type: "",
      user: undefined,
    },
    menuDate: false,
    menuTime: false,
    page: 1,
    previewDialog: false,
    selectedTests: [],
    test: new Test(),
    tests: [],
    time: null,
    textSearch: undefined,
    totalPages: 1,
  }),
  apollo: {
    // tests: {
    //   query: GET_TESTS_ENTITY_QUERY,
    //   variables () {
    //     return { filterTest: this.filter }
    //   }
    // }
  },
  // watch: {
  //   tests: function(val) {
  //     if (this.appliedFilter) {
  //       this.setTestsState(val.tests);
  //       this.appliedFilter = false;
  //     } else {
  //       this.setTestsScrolled(val.tests);
  //     }
  //     this.totalPages = val.totalPages;
  //   },
  // },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTests: "test/getTests",
    }),
    buttonsTitle() {
      return this.page === 1 ? this.$t("dateTime") : this.$t("submit_test");
    },
    dialogWidth() {
      return this.page === 1 ? "70%" : "30%";
    },
    filteredItems() {
      let resultItems = [...this.getTests];
      resultItems = resultItems.filter(
        (test) =>
          !(
            test.entity &&
            test.creatorType === "partner" &&
            !test.status &&
            test.reason != null
          ) && !test.isDraft
      );

      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }

      if (this.appliedFilter) {
        for (const field in this.appliedFilter) {
          const val = this.appliedFilter[field];
          if (val) {
            if (typeof val !== "string" && val.length > 0) {
              resultItems = resultItems.filter((c) => {
                let aux = c[field].filter((f) => val.indexOf(f) >= 0);

                if (aux.length > 0) {
                  return true;
                }
              });
            } else if (field === "user") {
              resultItems = resultItems.filter((i) => i[field].id === val);
            } else {
              resultItems = resultItems.filter((i) => i[field] === val);
            }
          }
        }
      }
      return resultItems;
    },
  },

  methods: {
    ...mapActions({
      removeTestState: "test/removeTest",
      setTestsState: "test/setTests",
      setTestsScrolled: "test/setTestsScrolled",
    }),
    async applyFilter() {
      this.appliedFilter = { ...this.filter };
    },
    applyTestFilter(filter) {
      this.filter = { ...filter };
      this.applyFilter();
    },
    changePage(page) {
      this.filter.page = page;
      this.applyFilter();
    },
    cleanFilter() {
      this.filter = {
        areas: undefined,
        category: "",
        entity: undefined,
        positions: undefined,
        type: "",
        user: undefined,
      };
      this.applyFilter();
    },
    closeDialog() {
      this.page = 1;
      this.selectedTests = [];
      this.$emit("close");
    },
    dateFormat() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    async fetch(page) {
      if (page > this.totalPages) {
        return;
      }
      this.filter.page = page;
      await this.$apollo.queries.tests.refetch();
    },
    nextStep() {
      this.page = 2;
    },
    selectTest(test) {
      const index = this.selectedTests.findIndex((t) => t.id == test.id);
      if (index > -1) {
        this.selectedTests.splice(index, 1);
      } else {
        this.selectedTests.push(
          Object.assign({}, { ...test, user: undefined })
        );
      }
    },
    async sendTest() {
      this.isLoading = true;
      try {
        if (this.vacancyTitle) {
          await this.$apollo.mutate({
            mutation: SEND_TESTS_IMPORTED_MUTATION,
            variables: {
              candidates: this.formatCandidates(),
              date: this.date,
              tests: this.selectedTests,
              time: this.time,
              vacancyTitle: this.vacancyTitle,
            },
          });
        } else {
          await this.$apollo.mutate({
            mutation: SEND_TESTS_IMPORTED_WITH_VACANCY_MUTATION,
            variables: {
              candidates: this.formatCandidates(),
              date: this.date,
              tests: this.selectedTests,
              time: this.time,
              vacancyId: this.vacancyId,
            },
          });
        }
        this.success = this.$t("send_test_success");
        this.showSuccess = true;
        this.closeDialog();
      } catch (error) {
        var obj = JSON.parse(error.message.split("GraphQL error:")[1]);
        var message = this.$t("send_test_err");
        console.log("Array de erros: ", obj);
        message = message.replace("test_title", obj.test);
        message = message.replace("name", obj.name);
        message = message.replace("lastname", obj.lastName);
        this.error = message;
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    showPreviewDialog(test) {
      this.test = test;
      this.previewDialog = true;
    },
    formatCandidates() {
      return this.candidates.map((c) => {
        delete c.age;
        delete c.certificates;
        delete c.entities;
        delete c.fullName;
        delete c.notes;
        delete c.belongsToMany;
        return c;
      });
    },
  },
};
</script>
<style scoped>
.scroll-test {
  height: 450px;
  overflow: auto;
}
</style>